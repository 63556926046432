<script setup lang="ts">
const appName = getAppName()

useHead({
  titleTemplate(title) {
    return title ? `${title} - ${appName}` : appName
  },
})
</script>

<template>
  <div class="flex flex-col h-full">
    <GlobalBanner />
    <div class="flex-1 flex h-full flex-col items-center justify-center px-4">
      <slot />
      <Background oval />
      <CopyrightFooter variant="light" class="absolute bottom-0" />
    </div>
  </div>
</template>
